import { PageSectionE } from 'pages/landing/types';

export enum RoutesE {
  Landing = '/',
  Frontends = '/frontends',
  Press = '/press',
  Tools = '/tools',
  Terms = '/terms',
  Disclaimer = '/disclaimer',
  Privacy = '/privacy',
  Analytics = '/analytics',
}

export const navItems = [
  { name: 'Abstract', link: `/#${PageSectionE.Abstract}` },
  { name: 'Features', link: `/#${PageSectionE.Features}` },
  { name: 'Whitelabeling', link: `/#${PageSectionE.Whitelabel}` },
  { name: 'FAQ', link: `/#${PageSectionE.FAQ}` },
  { name: 'Docs', link: `/#${PageSectionE.Documentation}` },
  { name: 'Partners', link: `/#${PageSectionE.Partners}` },
  { name: 'Press', link: `/#${PageSectionE.Press}` },
];
