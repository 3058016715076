import { useEffect, useState } from 'react';
import styles from './AnalyticsPage.module.scss';

interface ContractSpecsI {
  contract_type: string;
  contract_price_currency: string;
  contract_price: number;
  contract_collateral_currency: string;
}

interface ContractDataI {
  ticker_id: string;
  contract_specs: ContractSpecsI;
  base_currency: string;
  target_currency: string;
  last_price: number;
  base_volume: number;
  target_volume: number;
  high: number;
  low: number;
  product_type: string;
  open_interest: number;
  index_price: number;
  index_name: string;
  index_currency: string;
  funding_rate: number;
  next_funding_rate: number;
  next_funding_rate_timestamp: number;
  base_volume_30d: number;
  target_volume_30d: number;
}

interface ApiResponseI {
  chain_id: number;
  timestamp: number;
  contracts: ContractDataI[];
}

const CHAIN_IDS = [80094, 42161, 1101];
const CHAIN_NAMES: Record<number, string> = {
  80094: 'Berachain',
  42161: 'Arbitrum',
  1101: 'Polygon zkEVM',
};

export const AnalyticsPage = () => {
  const [contractData, setContractData] = useState<Record<number, ContractDataI[]>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results: Record<number, ContractDataI[]> = {};

        await Promise.all(
          CHAIN_IDS.map(async (chainId) => {
            const response = await fetch(`https://drip.d8x.xyz/coingecko/contracts?chain_id=${chainId}`);
            if (!response.ok) throw new Error(`Error fetching data for chain ${chainId}`);
            const data: ApiResponseI = await response.json();
            results[chainId] = data.contracts || [];
          })
        );

        setContractData(results);
        setIsLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch data');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <main className={styles.root}>
        <div className={styles.spinnerContainer}>Loading...</div>
      </main>
    );
  }

  if (error) {
    return (
      <main className={styles.root}>
        <div className={styles.error}>Error: {error}</div>
      </main>
    );
  }

  return (
    <main className={styles.root}>
      <div className={styles.titleContainer}>
        <h1 className="h1">Analytics</h1>
        <h2 className={styles.subtitle}>D8X.Exchange</h2>
      </div>

      <div className={styles.content}>
        {CHAIN_IDS.map((chainId) => {
          const contracts = contractData[chainId] || [];
          return (
            <div key={chainId} className={styles.chainSection}>
              <h2 className={styles.chainTitle}>{CHAIN_NAMES[chainId]}</h2>
              <div className={styles.contractsGrid}>
                {contracts.map((contract) => (
                  <div key={contract.ticker_id} className={styles.contractCard}>
                    <h3>{contract.ticker_id}</h3>
                    <div className={styles.contractDetails}>
                      <p>
                        <span>Base Currency</span>
                        <span>{contract.base_currency}</span>
                      </p>
                      <p>
                        <span>Last Price</span>
                        <span>
                          {contract.last_price.toFixed(2)} {contract.contract_specs.contract_price_currency}
                        </span>
                      </p>
                      <p>
                        <span>24h Volume</span>
                        <span>
                          {contract.target_volume.toFixed(2)} {contract.target_currency}
                        </span>
                      </p>
                      <p>
                        <span>30d Volume</span>
                        <span>
                          {contract.target_volume_30d.toFixed(2)} {contract.target_currency}
                        </span>
                      </p>
                      <p>
                        <span>Open Interest</span>
                        <span>
                          {contract.open_interest.toFixed(2)} {contract.base_currency}
                        </span>
                      </p>
                      <p>
                        <span>Funding Rate</span>
                        <span>{(contract.funding_rate * 100).toFixed(4)}%</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
};
